export default function Help() {
	return (
		<div className="background-color-base">
			<div className="container normal">
				<h1>Wo Hilfts</h1>
				<p>
					Die Beschwerde, die mit Homöopathie gelindert werden können, sind so
					unterschiedlich und vielfältig wie die Menschen selber.
				</p>
				<p style={{marginBottom: '5px'}}>
					<strong>Einige Beispiele:</strong>
				</p>
				<div className="row">
					<div className="col-md-4">
						<ul>
							<li>Angststörungen</li>
							<li>Apoplex und seine Folgen</li>
							<li>Asthma</li>
							<li>Atemwegbeschwerden</li>
						</ul>
					</div>
					<div className="col-md-4">
						<ul>
							<li>Beschwerden des Bewegungsapparats</li>
							<li>Erkältungen</li>
							<li>Harnweginfekte</li>
							<li>Herzbeschwerden</li>
						</ul>
					</div>
					<div className="col-md-4">
						<ul>
							<li>Kopfschmerzen</li>
							<li>Long-Covid 19</li>
							<li>Schwangerschaftsbeschwerden</li>
							<li>Verdauungsbeschwerden</li>
							<li>…</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	)
}
