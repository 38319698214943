export default function About() {
	return (
		<div className="container about d-flex justify-content-end">
			<div className="who-am-i" style={{ width: '400px' }}>
				<h1>Wer bin ich</h1>
				<p>
					Ich kam als Quereinsteigerin aus einem medizinischen Beruf in die Homöopathie.{' '}
					<span className="bold-gold">Von 2017 bis 2022</span> absolvierte ich die
					Ausbildung zur dipl. Homöopathin in SHI Homöopathie Schule in Zug.{' '}
					<span className="bold-gold">2022 bis jetzt:</span> Weiterbildungen in
					Homöopathie und Arbeit in eigener Praxis.
				</p>

				<p style={{ marginBottom: '0px' }}>
					<span className="bold-gold">Sprachen:</span> Deutsch, Russisch
				</p>
			</div>
		</div>
	)
}
