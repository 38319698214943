export default function Header() {
	function renderArrowDown(): JSX.Element {
		return (
			<a href="#page-calendar">
				<div>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						height="1em"
						viewBox="0 0 384 512"
						fill="white"
					>
						<path d="M169.4 470.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 370.8 224 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 306.7L54.6 265.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
					</svg>
				</div>
			</a>
		)
	}

	return (
		<div>
			<div className="d-flex justify-content-center">
				<div className="container" style={{ marginBottom: '-200px' }}>
					<video style={{ width: '100%' }} autoPlay loop>
						<source src="media/video.mp4" type="video/mp4" />
						Your browser does not support the video tag.
					</video>
				</div>
			</div>
			<div className="background-color-base text-center">
				<div className="container normal" style={{ paddingBottom: '8px' }}>
					<h2 style={{ color: 'white' }}>
						Mithilfe der Natur zu
						<br />
						<span style={{ fontWeight: '600' }}>besserer Gesundheit …</span>
					</h2>
					<p style={{ color: 'white', textDecoration: 'underline', marginBottom: '0px' }}>
						Jetzt gleich einen Termin vereinbaren
					</p>
					{renderArrowDown()}
				</div>
			</div>
		</div>
	)
}
